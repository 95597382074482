import { Button } from "./Button";
import { SocialColoredIcon } from "./Icons/SocialColored";

export const SocialLoginButtons = (props: {
    onClickGoogle?: () => void;
    onClickFacebook?: () => void;
}): JSX.Element => (
    <div className="grid grid-cols-2 gap-4">
        <Button
            variant="outlined"
            onClick={props.onClickGoogle}
            className="flex gap-2 font-bold"
        >
            <SocialColoredIcon type="Google" />
            Google
        </Button>
        <Button
            variant="outlined"
            onClick={props.onClickFacebook}
            className="flex gap-2 font-bold"
        >
            <SocialColoredIcon type="Facebook" />
            Facebook
        </Button>
    </div>
);
