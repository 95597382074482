import { captureException } from "@sentry/nextjs";
import type { FirebaseError } from "firebase/app";
import { toast } from "react-toastify";

const authErrorCode = {
    GFB: "Firebase error",
    GFBUH: "Unhandled Firebase error",
    ENJ: "Enjin error",
    ENJUH: "Unhandled Enjin error",
    ERRUH: "Unknown error",
};
type EnjinAuthErrorCode = keyof typeof authErrorCode;
const errorCodesToLog = new Set(["GFBUH", "ENJUH", "ERRUH"]);
const shouldLogEnjinAuthError = (err: EnjinAuthError): boolean =>
    errorCodesToLog.has(err.code);

export class EnjinAuthError extends Error {
    constructor(
        code: EnjinAuthErrorCode,
        message: string,
        options?: ErrorOptions,
    ) {
        super(message, options);
        this.code = code;
    }
    code: EnjinAuthErrorCode;
}

export const getAuthErrorMessage = (err: FirebaseError): EnjinAuthError => {
    switch (err.code) {
        case "auth/wrong-password":
        case "auth/user-not-found":
            return new EnjinAuthError("GFB", "Invalid email/password given.", {
                cause: err,
            });
        case "auth/network-request-failed":
            return new EnjinAuthError("GFB", "A network error occurred.", {
                cause: err,
            });
        case "auth/account-exists-with-different-credential":
        case "auth/email-already-in-use":
            return new EnjinAuthError(
                "GFB",
                "E-mail address entered is already in use.",
                { cause: err },
            );
        case "auth/cancelled-popup-request":
            return new EnjinAuthError("GFB", "Log in in progress.", {
                cause: err,
            });
        case "auth/operation-not-allowed":
            return new EnjinAuthError(
                "GFB",
                "Social log in not allowed for your account.",
                { cause: err },
            );
        case "auth/popup-blocked":
            return new EnjinAuthError("GFB", "Popup is blocked.", {
                cause: err,
            });
        case "auth/user-cancelled":
            return new EnjinAuthError("GFB", "Log in was cancelled.", {
                cause: err,
            });
        case "auth/popup-closed-by-user":
            return new EnjinAuthError("GFB", "Log in was cancelled.", {
                cause: err,
            });
        case "auth/too-many-requests":
            return new EnjinAuthError(
                "GFB",
                "Too many attempts, please try again in 30 minutes.",
                { cause: err },
            );
        default:
            // auth/operation-not-allowed
            return new EnjinAuthError(
                "GFBUH",
                "An unknown error occurred, please try again later.",
                { cause: err },
            );
    }
};
export const authErrorHandler = (err: unknown): void => {
    if (!(err instanceof Error)) {
        captureException(new Error("Unknown auth error"), (ctx) =>
            ctx.setExtra("error", err),
        );
        toast.error(
            "Unknown error occurred, please contact our customer service.",
        );
        return;
    }
    if (!(err instanceof EnjinAuthError)) {
        captureException(err);
        toast.error(
            "Unknown error occurred, please contact our customer service.",
        );
        return;
    }
    if (shouldLogEnjinAuthError(err)) {
        captureException(err);
    }
    toast.error(err.message);
};
