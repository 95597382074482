import { useMutation } from "@apollo/client";
import {
    FacebookAuthProvider,
    GoogleAuthProvider,
    type UserCredential,
    signInWithPopup,
} from "firebase/auth";
import { graphql } from "../gql";
import { auth } from "./auth";
import { EnjinAuthError, getAuthErrorMessage } from "./enjinAuthError";
import { isAuthError } from "./isAuthError";

export const useSocialLogins = (): {
    googleHandler: () => Promise<void>;
    facebookHandler: () => Promise<void>;
} => {
    const [createUserProfile] = useMutation(mut);

    const googleHandler = async (): Promise<void> => {
        await handleSocialAuth(() =>
            signInWithPopup(auth, new GoogleAuthProvider().addScope("email")),
        );
    };
    const facebookHandler = async (): Promise<void> => {
        await handleSocialAuth(() =>
            signInWithPopup(auth, new FacebookAuthProvider().addScope("email")),
        );
    };

    const handleSocialAuth = async (
        authFn: () => Promise<UserCredential>,
    ): Promise<void> => {
        const response = await authFn().catch((error) => {
            if (!isAuthError(error)) {
                throw new EnjinAuthError(
                    "GFBUH",
                    "An unknown error occurred, please try again later.",
                    { cause: error },
                );
            }
            throw getAuthErrorMessage(error);
        });
        if (!response.user.uid) {
            throw new EnjinAuthError(
                "GFBUH",
                "No user retrieved from response.",
            );
        }
        const resp = await createUserProfile({
            variables: {
                name: response.user.displayName ?? "",
                email: response.user.email,
            },
        });
        if (resp.errors?.length) {
            throw new EnjinAuthError(
                "ENJUH",
                "Failed to create profile, please contact our customer service.",
                { cause: resp.errors },
            );
        }
        if (!resp.data) {
            throw new EnjinAuthError(
                "ENJUH",
                "Unknown application state, please contact our customer service.",
            );
        }
        const data = resp.data.createUserProfile;
        if (data.__typename === "AccessError") {
            throw new EnjinAuthError(
                "ENJ",
                "There was an issue with your account's authorization, please try again or contact our customer service.",
                { cause: new Error(`${data.__typename}:${data.message}`) },
            );
        }
        if (data.__typename === "InvalidValue") {
            throw new EnjinAuthError(
                "ENJ",
                "There was an issue with your account's details, please try again or contact our customer service.",
                { cause: new Error(`${data.__typename}:${data.message}`) },
            );
        }
    };

    return { googleHandler, facebookHandler };
};

const mut = graphql(`
    mutation createUserProfile(
        $name: String!
        $email: EmailAddress
        $phoneNumber: PhoneNumber
    ) {
        createUserProfile(
            request: { name: $name, email: $email, phoneNumber: $phoneNumber }
        ) {
            __typename
            ... on AccessError {
                message
            }
            ... on InvalidValue {
                message
            }
        }
    }
`);
