import { type VariantProps, cva, cx } from "class-variance-authority";
import { type InputHTMLAttributes, type ReactNode, forwardRef } from "react";

export interface InputProps
    extends InputHTMLAttributes<HTMLInputElement>,
        VariantProps<typeof inputVariants> {}

const inputVariants = cva(
    "flex w-full h-10 items-center gap-3 border-solid border-blue-grey-50 px-3 py-2.5 has-[:disabled]:bg-blue-grey-20 group-data-[error=true]:border-destructive-600 group-data-[error=true]:hover:border-blue-grey-200 active:border-primary hover:border-blue-grey-200 focus:border-primary focus-within:border-primary has-[:disabled]:hover:border-blue-grey-50 has-[:disabled]:active:border-blue-grey-50 has-[:disabled]:pointer-events-none",
    {
        variants: {
            variant: {
                outline: "border bg-white rounded-lg",
                underline: "border-b border-0",
            },
        },
        defaultVariants: {
            variant: "outline",
        },
    },
);

const Input = forwardRef<
    HTMLInputElement,
    InputProps & {
        prefixChild?: ReactNode;
        suffixChild?: ReactNode;
    }
>(
    (
        {
            prefixChild,
            suffixChild,
            value,
            placeholder,
            className,
            variant,
            ...props
        },
        ref,
    ): JSX.Element => (
        <div className={cx(inputVariants({ variant, className }))}>
            {prefixChild && <div>{prefixChild}</div>}
            <input
                ref={ref}
                value={value}
                placeholder={placeholder}
                className="typography-sub h-10 w-full border-0 bg-transparent p-0 text-left text-blue-grey-900 caret-primary-500 outline-none disabled:text-blue-grey-300"
                {...props}
            />
            {suffixChild && <div>{suffixChild}</div>}
        </div>
    ),
);
Input.displayName = "Input";

export { Input };
