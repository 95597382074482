import type { SVGProps } from "./types";

export const EyeUnfilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12.2004 10C12.2004 11.2155 11.2151 12.2008 9.99967 12.2008C8.78423 12.2008 7.79892 11.2155 7.79892 10C7.79892 8.78457 8.78423 7.79926 9.99967 7.79926C11.2151 7.79926 12.2004 8.78457 12.2004 10Z"
            stroke="currentColor"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M3 9.99999C3.93479 7.02378 6.71528 4.86493 9.99999 4.86493C13.2847 4.86493 16.0652 7.0238 17 10C16.0652 12.9763 13.2847 15.1351 10 15.1351C6.71527 15.1351 3.93477 12.9762 3 9.99999Z"
            stroke="currentColor"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const EyeFilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10 11.4672C10.8103 11.4672 11.4672 10.8103 11.4672 10C11.4672 9.18972 10.8103 8.53285 10 8.53285C9.18974 8.53285 8.53287 9.18972 8.53287 10C8.53287 10.8103 9.18974 11.4672 10 11.4672Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 10C3.93477 7.0238 6.71527 4.86493 10 4.86493C13.2847 4.86493 16.0652 7.02378 17 9.99999C16.0652 12.9762 13.2847 15.1351 9.99999 15.1351C6.71528 15.1351 3.93479 12.9763 3 10ZM12.9344 10C12.9344 11.6206 11.6206 12.9344 10 12.9344C8.37945 12.9344 7.0657 11.6206 7.0657 10C7.0657 8.37943 8.37945 7.06568 10 7.06568C11.6206 7.06568 12.9344 8.37943 12.9344 10Z"
            fill="currentColor"
        />
    </svg>
);

export const EyeSlashUnfilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3.39742 3.39775L6.03037 6.0307M16.6019 16.6023L13.9692 13.9696M11.375 15.0064C10.9296 15.0909 10.47 15.1351 10 15.1351C6.71527 15.1351 3.93477 12.9762 3 9.99998C3.25448 9.18976 3.64574 8.44012 4.14672 7.77811M8.4435 8.44384C8.84176 8.04558 9.39195 7.79925 9.99967 7.79925C11.2151 7.79925 12.2004 8.78456 12.2004 10C12.2004 10.6077 11.9541 11.1579 11.5558 11.5562M8.4435 8.44384L11.5558 11.5562M8.4435 8.44384L6.03037 6.0307M11.5558 11.5562L6.03037 6.0307M11.5558 11.5562L13.9692 13.9696M6.03037 6.0307C7.17463 5.293 8.53732 4.86492 9.99999 4.86492C13.2847 4.86492 16.0652 7.02379 17 10C16.4814 11.6511 15.3948 13.0506 13.9692 13.9696"
            stroke="currentColor"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
