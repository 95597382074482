import type { SVGProps } from "./types";

const Facebook = (props: SVGProps): JSX.Element => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_384_131459)">
            <path
                d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9701 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z"
                fill="#1877F2"
            />
            <path
                d="M16.6711 15.4688L17.2031 12H13.875V9.75C13.875 8.80102 14.34 7.875 15.8306 7.875H17.3438V4.92188C17.3438 4.92188 15.9705 4.6875 14.6576 4.6875C11.9166 4.6875 10.125 6.34875 10.125 9.35625V12H7.07812V15.4688H10.125V23.8542C11.3674 24.0486 12.6326 24.0486 13.875 23.8542V15.4688H16.6711Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_384_131459">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

const Google = (props: SVGProps): JSX.Element => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_384_131471)">
            <path
                d="M23.7663 12.2765C23.7663 11.4608 23.7001 10.6406 23.559 9.83813H12.2402V14.4591H18.722C18.453 15.9495 17.5888 17.2679 16.3233 18.1056V21.104H20.1903C22.4611 19.014 23.7663 15.9274 23.7663 12.2765Z"
                fill="#4285F4"
            />
            <path
                d="M12.2401 24.0008C15.4766 24.0008 18.2059 22.9382 20.1945 21.1039L16.3276 18.1055C15.2517 18.8375 13.8627 19.252 12.2445 19.252C9.11388 19.252 6.45946 17.1399 5.50705 14.3003H1.5166V17.3912C3.55371 21.4434 7.7029 24.0008 12.2401 24.0008Z"
                fill="#34A853"
            />
            <path
                d="M5.50277 14.3002C5.00011 12.8099 5.00011 11.196 5.50277 9.70569V6.61475H1.51674C-0.185266 10.0055 -0.185266 14.0004 1.51674 17.3912L5.50277 14.3002Z"
                fill="#FBBC04"
            />
            <path
                d="M12.2401 4.74966C13.9509 4.7232 15.6044 5.36697 16.8434 6.54867L20.2695 3.12262C18.1001 1.0855 15.2208 -0.034466 12.2401 0.000808666C7.7029 0.000808666 3.55371 2.55822 1.5166 6.61481L5.50264 9.70575C6.45064 6.86173 9.10947 4.74966 12.2401 4.74966Z"
                fill="#EA4335"
            />
        </g>
        <defs>
            <clipPath id="clip0_384_131471">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

const Whatsapp = (props: SVGProps): JSX.Element => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.4 3.45C18.15 1.2 15.15 0 12 0C5.4 0 0 5.4 0 12C0 14.1 0.600009 16.2 1.65001 18L0 24L6.30002 22.35C8.10002 23.25 10.05 23.85 12 23.85C18.6 23.85 24 18.45 24 11.85C24 8.7 22.65 5.7 20.4 3.45ZM12 21.9C10.2 21.9 8.40001 21.45 6.90001 20.55L6.59999 20.4L2.84999 21.45L3.90001 17.85L3.59999 17.4C2.54999 15.75 2.09999 13.95 2.09999 12.15C2.09999 6.75 6.6 2.25 12 2.25C14.7 2.25 17.1 3.3 19.05 5.1C21 7.05 21.9 9.45 21.9 12.15C21.9 17.4 17.55 21.9 12 21.9ZM17.4 14.4C17.1 14.25 15.6 13.5 15.3 13.5C15 13.35 14.85 13.35 14.7 13.65C14.55 13.95 13.95 14.55 13.8 14.85C13.65 15 13.5 15 13.2 15C12.9 14.85 12 14.55 10.8 13.5C9.90002 12.75 9.30001 11.7 9.15001 11.4C9.00001 11.1 9.15002 10.95 9.30002 10.8C9.45002 10.65 9.6 10.5 9.75 10.35C9.9 10.2 9.90002 10.05 10.05 9.9C10.2 9.75 10.05 9.6 10.05 9.45C10.05 9.3 9.45001 7.8 9.15001 7.2C9.00001 6.75 8.70002 6.75 8.55002 6.75C8.40002 6.75 8.24998 6.75 7.94998 6.75C7.79998 6.75 7.49998 6.75 7.19998 7.05C6.89998 7.35 6.15001 8.1 6.15001 9.6C6.15001 11.1 7.19999 12.45 7.34999 12.75C7.49999 12.9 9.44998 16.05 12.45 17.25C15 18.3 15.45 18 16.05 18C16.65 18 17.85 17.25 18 16.65C18.3 15.9 18.3 15.3 18.15 15.3C18 14.55 17.7 14.55 17.4 14.4Z"
            fill="#25D366"
        />
    </svg>
);

const icons = { Facebook: Facebook, Google: Google, Whatsapp: Whatsapp };
type iconTypes = keyof typeof icons;
type SocialColoredIconProps = { type: iconTypes } & SVGProps;
export const SocialColoredIcon = ({
    type,
    ...props
}: SocialColoredIconProps): JSX.Element => {
    const Comp = icons[type];
    return <Comp {...props} />;
};
